:root {
    --color-primary: #000000;
    --color-theme-active: #E31F3A;
    --color-theme-style: #C49102;
    --color-secondary: #E31F3A;
    --color-third: #43505b;
    --color-text-default: #000000;
    --color-text-muted: #868E96;
    --color-highlight: var(--color-season-5);

    --color-season-1: #636751; /* Moss */
    --color-season-2: #FAAF19; /* Curry */
    --color-season-3: #AEAAA6; /* Stone */
    --color-season-4: #B8BCA5; /* Soft-Moss */
    --color-season-5: #E3CC9E; /* Soft-Curry */
    --color-season-6: #D3CEC8; /* Soft-Stone */
    --color-season-7: #E4B7B8; /* Soft-Fiery */

    --color-default: #000000;
    --color-white: #FFF;
    --color-light-grey: #F0F0F0;
    --color-grey: #E3E3E3;
    --color-dark-grey: #838383;
    --color-dark: #1e1e1e;

    --color-success: #12862b;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #000000;
    --color-secondary-dark: #C61F36;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #393939;
    --color-secondary-light: #f32642;
    --color-success-light: #65EF76;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "HelveticaNow-Regular", sans-serif;
    --font-default-medium: "HelveticaNow-Regular", sans-serif;
    --font-default-bold: "HelveticaNow-Bold", sans-serif;
    --font-title-default: "DrukWide-Medium-Web", sans-serif;
    --font-title-bold: "DrukTextWide-Medium-Web", sans-serif;


    --font-special: "ReenieBeanie", sans-serif;

    --font-size-default: calc(14rem/16);

    --icon-cookie-arrow: var(--icon-arrow-down);
}