.floating-label {
    position: absolute;
    left: .75rem;
    top: .5rem;
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(-1.5rem) scale(.75);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-1.5rem) scale(.75);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-1.5rem) scale(.75);
}
